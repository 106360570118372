
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import moment from 'moment';

import axios from 'axios';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'finman-purchase',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      load: false,
      editItemIndex:-1,
      componentKey: 0,
      alertText: false,
      role_title: [] as any,
      actionCreate: false,
      createTrainer: false,
      text: '',
      file_name: '',
      cash_switch: 'no',
      bank_switch: 'no',
      tahir: false,
      product: {
        id:0,
        po_no: '',
        date: '',
        due_date:'',
        supplier_id: '',
        supplier_address:'',
      } as any,
      updateButton: false,
      supplierList: [] as any,
      professional_details: [
        {
          designation: '',
          organization: '',
          address: '',
          exp_from: '',
          exp_to: '',
        },
      ] as any,
      product_details: {
        id: 0,
        index:0,
        product_id:'' as any,
        category_name:'' as any,
        product_name:'' as any,
        brand:'' as any,
        color:'' as any,
        model:'' as any,
        product_type_id: '' as any,
        quantity: '' as any,
        weight:'' as any,
        usd_cost:'' as any,
        exchange_rate:'' as any,
        taka_cost:'' as any,
        freight_amount:'' as any,
        cost_with_freight_amount:'' as any,
        insurance_amount:'' as any,
        other_costs:'' as any,
        cif_value:'' as any,
        adjustment:'' as any,
        unit_price: '' as any,
        assessable_value: '' as any,
        sd_percentage: '' as any,
        vat_percentage: '' as any,
        cd_percentage: '' as any,
        rd_percentage: '' as any,
        sd_amount: '' as any,
        cd_amount: '' as any,
        rd_amount: '' as any,
        vat_amount: '' as any,
        net_total: '' as any,
        sd_impose_value: '' as any,
        vat_impose_value: '' as any,
        product_category_id: '',
        inventory_ledger_id: '',
        at_ledger_id:'',
        ait_ledger_id:'',
        vat_ledger_id: '',
        category_id: '',
        ait_percentage: '' as any,
        ait_amount:'' as any,
        at_percentage:'' as any,
        at_amount: '' as any,
        size_unit: '',
        size_measurement:'',
        weight_unit:'',
        weight_measurement:'',
        g_tax_amount:'',
      } as any,
      url: '',
      productlistData: [] as any,
      categoryList: [] as any,
      loading: false,
      product_details_data: [] as any,
      finmanUserId: '' as any,
      product_info_data: {} as any,
      grand_total: '' as any,
      editMode: 0,
    };
  },
  async created() {
    if (VueCookieNext.getCookie('finman_token') != null) {
      let user_id = (this.finmanUserId = VueCookieNext.getCookie('user_id'));
      this.load=true;
      await this.getInvoiceNumber();
      await this.getSupplierList();
      this.load=false;
      if(this.$route.query.id && Number(this.$route.query.id)>0)
      {
        this.setPurchaseData();
        this.editMode=1;
      }
      this.finmanUserId = user_id;
    } else {
      this.$router.push('/404');
    }

    if (VueCookieNext.getCookie('purchase_date_'+this.finmanUserId) != null) {
      let date= VueCookieNext.getCookie('purchase_date_'+this.finmanUserId);
      this.product.date = moment(date).format('YYYY-MM-DD');
    }
    else
    {
      this.product.date = moment(new Date()).format('YYYY-MM-DD');
    }
    this.load=false;
    // check tahir api  if success set user_id
  },
  methods: {
    saveDateCookie()
    {
      VueCookieNext.setCookie('purchase_date_'+this.finmanUserId, this.product.date);
    },
    async setPurchaseData()
    {
      this.load=true;
      let invoice_id = this.$route.query.id;

      ApiService.get("finmanInvoice/invoice/getPurchaseDetails/"+ `${invoice_id}`)
        .then((response) => {
            let invoice= response.data.data.invoice;
            this.product.id= Number(invoice.id)>0?Number(invoice.id):Number(this.$route.query.id);
            this.product.po_no= invoice.po_no;
            this.product.date= invoice.date;
            this.product.due_date= invoice.due_date;
            this.product.supplier_id= invoice.supplier_id;
            this.product.supplier_name= invoice.supplier_name;
            this.product.grand_total= invoice.grand_total;
            this.product.finman_user_id= this.finmanUserId;  
            this.product_details_data=response.data.data.invoice_details;
            this.load=false;    
            this.setSupplierData();   
        }).catch(({ response }) => {

        });
    },
    setSupplierData()
    {
      let supplier= this.supplierList.find(element => element.id == this.product.supplier_id);
      this.product.supplier_address= supplier.address;
    },
    async getInvoiceNumber() {
      await ApiService.get(
        'finmanInvoice/getInvoicePurchaseNumber?finman_user_id=' +
          this.finmanUserId
      )
        .then((response) => {
          this.product.po_no = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    addProduct() {
      let product= this.productlistData.find(element => element.id == this.product_details.product_id);
      this.product_details.product_name= product.product_name;
      this.product_details.category_name= product.category_name;
      this.product_details.brand= product.brand;
      this.product_details.model= product.model;
      this.product_details.color= product.color;
      this.product_details.at_ledger_id= product.at_ledger_id;
      this.product_details.inventory_ledger_id= product.inventory_ledger_id;
      this.product_details.vat_ledger_id= product.vat_ledger_id;
      this.product_details.ait_ledger_id= product.ait_ledger_id;
      this.product_details_data.push(JSON.parse(JSON.stringify(this.product_details)));
      for (let key in this.product_details) {
        if (this.product_details.hasOwnProperty(key)) {
          this.product_details[key] = '';
        }
      }
      this.product_info_data={};
    },
    updateProduct() {
      let product= this.productlistData.find(element => element.id == this.product_details.product_id);
      this.product_details.product_name= product.product_name;
      this.product_details.category_name= product.category_name;
      this.product_details.brand= product.brand;
      this.product_details.model= product.model;
      this.product_details.color= product.color;
      this.product_details.at_ledger_id= product.at_ledger_id;
      this.product_details.inventory_ledger_id= product.inventory_ledger_id;
      this.product_details.vat_ledger_id= product.vat_ledger_id;
      this.product_details.ait_ledger_id= product.ait_ledger_id;
      this.product_details_data[this.editItemIndex]={};
      this.product_details_data[this.editItemIndex]=JSON.parse(JSON.stringify(this.product_details));
      for (let key in this.product_details) {
        if (this.product_details.hasOwnProperty(key)) {
          this.product_details[key] = '';
        }
      }
      this.product_info_data={};
    },
    async editProduct(data, index) {
        this.editItemIndex= index;
        this.product_details.id= parseInt(data.id);
        this.product_details.product_type_id= parseInt(data.product_type_id);
        await this.getCategoryData();
        this.product_details.product_category_id=data.product_category_id ;
        await this.productlist();
        this.product_details.product_id= data.product_id;
        this.product_details.weight= data.weight;
        this.product_details.usd_cost=data.usd_cost ;
        this.product_details.exchange_rate=data.exchange_rate ;
        this.product_details.taka_cost=data.taka_cost ;
        this.product_details.freight_amount=data.freight_amount ;
        this.product_details.cost_with_freight_amount=data.cost_with_freight_amount ;
        this.product_details.insurance_amount=data.insurance_amount ;
        this.product_details.other_costs=data.other_costs ;
        this.product_details.cif_value=data.cif_value ;
        this.product_details.adjustment=data.adjustment ;
        this.product_details.unit_price= data.unit_price ;
        this.product_details.assessable_value= data.assessable_value ;
        this.product_details.sd_percentage= data.sd_percentage ;
        this.product_details.vat_percentage= data.vat_percentage ;
        this.product_details.cd_percentage= data.cd_percentage ;
        this.product_details.rd_percentage= data.rd_percentage ;
        this.product_details.sd_amount= data.sd_amount ;
        this.product_details.cd_amount= data.cd_amount ;
        this.product_details.rd_amount= data.rd_amount ;
        this.product_details.vat_amount= data.vat_amount ;
        this.product_details.net_total= data.net_total ;
        this.product_details.sd_impose_value= data.sd_impose_value ;
        this.product_details.vat_impose_value= data.vat_impose_value;
        this.product_details.inventory_ledger_id=data.inventory_ledger_id ;
        this.product_details.at_ledger_id= data.at_ledger_id;
        this.product_details.ait_ledger_id= data.ait_ledger_id;
        this.product_details.vat_ledger_id= data.vat_ledger_id;
        this.product_details.category_id= data.category_id;
        this.product_details.product_id= data.product_id;
        this.product_details.ait_percentage= data. ait_percentage;
        this.product_details.ait_amount=data.ait_amount ;
        this.product_details.at_percentage=data.at_percentage ;
        this.product_details.at_amount= data.at_amount ;
        this.product_details.size_unit= data.size_unit;
        this.product_details.size_measurement=data.size_measurement;
        this.product_details.weight_unit=data.weight_unit;
        this.product_details.weight_measurement=data.weight_measurement;
        this.product_details.g_tax_amount=data.g_tax_amount;
        this.calculateData();
        this.updateButton=true;
    },
    async calculateData() {
      var usd_cost= this.product_details.usd_cost;
      var exchange_rate= this.product_details.exchange_rate;
      var taka_cost= Number(usd_cost)*Number(exchange_rate);
      this.product_details.taka_cost= Number(taka_cost);
      var freight_amount=  Number(taka_cost)*0.01;
      this.product_details.freight_amount= Number(freight_amount);
      this.product_details.cost_with_freight_amount= Number(freight_amount)+Number(taka_cost);
      this.product_details.insurance_amount= Number(this.product_details.cost_with_freight_amount)*0.01;
      this.product_details.other_costs= Number(this.product_details.freight_amount)+Number(this.product_details.insurance_amount);
      this.product_details.cif_value= Number(this.product_details.cost_with_freight_amount)+Number(this.product_details.insurance_amount);
      this.product_details.assessable_value= Number(this.product_details.cif_value)+Number(this.product_details.adjustment);
      this.product_details.cd_amount= Number(this.product_details.assessable_value)*(Number(this.product_details.cd_percentage)/100);
      this.product_details.rd_amount= Number(this.product_details.assessable_value)*(Number(this.product_details.rd_percentage)/100);
      this.product_details.sd_impose_value= Number(this.product_details.cd_amount)+Number(this.product_details.rd_amount);
      this.product_details.sd_amount= Number(this.product_details.sd_impose_value) * (Number(this.product_details.sd_percentage)/100);

      this.product_details.vat_impose_value= Number(this.product_details.assessable_value)
                            +Number(this.product_details.cd_amount)
                            +Number(this.product_details.rd_amount)
                            +Number(this.product_details.sd_amount);
      this.product_details.vat_amount= Number(this.product_details.vat_impose_value)*(Number(this.product_details.vat_percentage)/100);
      this.product_details.ait_amount= Number(this.product_details.assessable_value)*(Number(this.product_details.ait_percentage)/100);
      this.product_details.at_amount= Number(this.product_details.vat_impose_value)*(Number(this.product_details.at_percentage)/100);
    },

    async getCategoryData() {
      var type_id= this.product_details.product_type_id;
      this.load = true;
      await ApiService.get('finmanInvoice/category/list?type='+type_id)
        .then((response) => {
          this.categoryList = response.data.data;

          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async getSupplierList() {
      this.load = true;
      await ApiService.get('finmanInvoice/supplier/list')
        .then((response) => {
          this.supplierList = response.data.data;

          this.componentKey += 1;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },
    async productlist() {
      var category_id= this.product_details.product_category_id;
      this.load = true;
      await ApiService.get(
        'finmanInvoice/product/listForInvoice?category_id=' + category_id
      )
        .then((response) => {
          this.productlistData = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
          this.load = false;
        });
    },


    removeProduct(data) {
      const productitemIndex = this.product_details_data.indexOf(data);
      if (productitemIndex > -1) {
        // only splice array when item is found
        this.product_details_data.splice(productitemIndex, 1); // 2nd parameter means remove one item only
      }
    },


    async formSubmit() {
      let formData = new FormData();
      for (var key in this.product) {
        formData.set(key, this.product[key]);
      }
      formData.set(
        'product_details',
        JSON.stringify(this.product_details_data)
      );
      formData.set('finmanUserId', this.finmanUserId);
      this.loading = true;

      // const grandtotal = this.product_details_data.reduce(
      //   (accumulator, object) => {
      //     return accumulator + object.net_total;
      //   },
      //   0
      // );
      // formData.set('grand_total', grandtotal);

      await ApiService.post('finmanInvoice/purchasesave', formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200 && response?.data?.status == 'success') {
            Swal.fire({
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.product = {
                id:0,
                po_no: '',
                date: '',
                due_date:'',
                supplier_id: '',
                supplier_address:'',
              };
              this.product_details_data = [] as any;
              if(this.editMode==0)
              {
                location.reload();
              }
              else
              {
                this.$router.push('/purchase-invoices');
              }
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: response.data.errors,
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          console.log(response);
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html:
              response?.data?.error ||
              'Unknown error occured. Please check all the required field',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
});
